import React, { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const StatusCounter = ({ endValue, suffix, statusName, duration = 2 }) => {
  const [hasViewed, setHasViewed] = useState(false);

  return (
    <div>
      <VisibilitySensor
        onChange={(isVisible) => {
          if (isVisible) {
            setHasViewed(true);
          }
        }}
        delayedCall
      >
        <h3 className="status-counter w-full text-5xl font-medium text-black text-center">
          {hasViewed ? (
            <>
              <CountUp start={0} end={endValue} duration={duration} />
              <span className="ml-1 text-2xl font-normal">{suffix}</span>
              <span className="mt-2 block text-base font-normal">{statusName}</span>
            </>
          ) : (
            <>
              {`0`}
              <span className="ml-1 text-2xl font-normal">{suffix}</span>
              <span className="mt-2 block text-base font-normal">{statusName}</span>
            </>
          )}
        </h3>
      </VisibilitySensor>
    </div>
  );
};

export default StatusCounter;