import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage, getSrc } from "gatsby-plugin-image"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import styled from 'styled-components'
import { RichText } from "prismic-reactjs"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from "../components/layout/layout"
import SEOnew from "../components/seo-new"
import htmlSerializer from "../utils/htmlSerializer"
import CapabilitiesSection from "../components/sections/capabilities-section"
import BenefitsSection from "../components/sections/benefits-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"

const StyledH2 = styled.h2`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const StyledDiv = styled.div`
  .accordion__button {
    color: #1f2937;
    font-weight: 700;
    font-size: 28px;
    background-color: #ffffff;
  }
  .accordion__button:before {
    color: #4183c4;
    height: 18px;
    width: 18px;
    margin-right: 14px;
  }
  .accordion__item {
    font-size: 18px;
    background-color: #ffffff;
    border: 1px solid #4183c4;
  }
`

const WhyNowPageTemplate = ({ data }) => {
  const doc = data.prismicWhyNowPage
  const imgSrc = getSrc(doc.data.hero_section_background_image)
  const imgHero = getImage(doc.data.hero_section_background_image)
  const imgDefault = getImage(data.imgDefault)
  const firstIcon = getImage(doc.data.first_process_icon)
  const imgDefaultFirstIcon = getImage(data.imgDefaultFirstIcon)
  const secondIcon = getImage(doc.data.second_process_icon)
  const imgDefaultSecondIcon = getImage(data.imgDefaultSecondIcon)
  const thirdIcon = getImage(doc.data.third_process_icon)
  const imgDefaultThirdIcon = getImage(data.imgDefaultThirdIcon)

  return (
    <Layout>
      <SEOnew 
        title={doc.data.page_title ? doc.data.page_title : null} 
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
        noIndex={doc.data.page_no_index ? true : false}
      />
      
      <div style={{ display: "grid" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            height: 400,
          }}
          width={1920}
          layout="constrained"
          alt={imgHero.alt ? imgHero.alt : "hero background image"}
          image={imgHero ? imgHero : imgDefault}
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
            <div className='w-11/12 flex flex-col items-center mx-auto'>
              <StyledH2 className='text-3xl md:text-5xl font-extrabold text-white uppercase text-center'>
                <span className='leading-tight'>{doc.data.hero_section_main_title ? doc.data.hero_section_main_title : "Hero Main Title"}</span>
              </StyledH2>

              <Link to="/quote">
                <button className="bg-red-600 hover:bg-red-700 text-white font-display px-8 py-4 text-xl md:text-2xl font-semibold tracking-wide rounded mt-8 shadow-lg">
                  Get a Quote
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white w-full border-b py-16">
        <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>{doc.data.intro_section_title ? doc.data.intro_section_title : "Intro Section Title"}</h2>

        <div className="w-11/12 max-w-screen-xl flex flex-col xl:flex-row items-center justify-center mx-auto">
          <div className='intro-section-content w-full lg:w-2/3 py-2 px-6'>
            {doc.data.intro_section_content.richText
              ?
              <RichText
                render={doc.data.intro_section_content.richText}
                htmlSerializer={htmlSerializer}
              />
              :
              <>
                <h3 className='text-2xl md:text-3xl text-gray-800 font-bold mb-6'>Intro Section Sub-Title</h3>

                <p className='text-lg text-gray-800 text-center'>Intro section content here..</p>
              </>
            }
          </div>

          <div className='w-full lg:w-1/3 border-4 border-site-lightblue p-6'>
            <h2 className='text-lg uppercase text-site-lightblue font-bold underline tracking-widest mb-8'>{doc.data.fact_section_title ? doc.data.fact_section_title : "Fact Section Title:"}</h2>

            <p className='text-xl md:text-2xl text-gray-800 text-center font-semibold mx-4'>
              {doc.data.fact_section_description ? doc.data.fact_section_description : "Fact section description here..."}
            </p>

            <p className='text-site-lightblue text-right mt-8'>Source: {doc.data.fact_section_source ? doc.data.fact_section_source : "Source Name"}</p>
          </div>
        </div>
      </div>

      <div className="bg-white w-full py-16 border-t border-b">
        <div className="w-11/12 max-w-screen-xl mx-auto">
          <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>{doc.data.process_section_title ? doc.data.process_section_title : "Process Section Title"}</h2>
    
          <div className='grid grid-cols-1 xl:grid-cols-3 gap-8'>
            <div>
              <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold text-center uppercase mb-6'>{doc.data.first_process_title ? doc.data.first_process_title : "Process Title"}</h2>
              <div className='w-48 w- h-48 mx-auto'>
                <GatsbyImage
                  width={800}
                  layout="constrained"
                  alt={firstIcon.alt ? firstIcon.alt : "Process Icon"}
                  image={firstIcon ? firstIcon : imgDefaultFirstIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className='text-3xl text-site-lightblue font-bold text-center mt-6 mb-6 border-b pb-3'>{doc.data. first_process_length ? doc.data. first_process_length : "Process lenght"}</h3>
              <p className='text-lg text-gray-800 text-center'>{doc.data.first_process_description ? doc.data.first_process_description : "Process description here..."}</p>
            </div>
    
            <div>
              <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold text-center uppercase mb-6'>{doc.data.second_process_title ? doc.data.second_process_title : "Process Title"}</h2>
              <div className='w-48 w- h-48 mx-auto'>
                <GatsbyImage
                  width={800}
                  layout="constrained"
                  alt={secondIcon.alt ? secondIcon.alt : "Process Icon"}
                  image={secondIcon ? secondIcon : imgDefaultSecondIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className='text-3xl text-site-lightblue font-bold text-center mt-6 mb-6 border-b pb-3'>{doc.data. second_process_length ? doc.data.second_process_length : "Process lenght"}</h3>
              <p className='text-lg text-gray-800 text-center'>{doc.data.second_process_description ? doc.data.second_process_description : "Process description here..."}</p>
            </div>
    
            <div>
              <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold text-center uppercase mb-6'>{doc.data.third_process_title ? doc.data.third_process_title : "Process Title"}</h2>
              <div className='w-48 w- h-48 mx-auto'>
                <GatsbyImage
                  width={800}
                  layout="constrained"
                  alt={thirdIcon.alt ? thirdIcon.alt : "Process Icon"}
                  image={thirdIcon ? thirdIcon : imgDefaultThirdIcon}
                  formats={["auto", "webp", "avif"]}
                />
              </div>
              <h3 className='text-3xl text-site-lightblue font-bold text-center mt-6 mb-6 border-b pb-3'>{doc.data. third_process_length ? doc.data.third_process_length : "Process lenght"}</h3>
              <p className='text-lg text-gray-800 text-center'>{doc.data.third_process_description ? doc.data.third_process_description : "Process description here..."}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 w-full py-16">
        <div className="w-11/12 max-w-screen-lg mx-auto">
          <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-8'>{doc.data.reasons_section_title ? doc.data.reasons_section_title : "Reason Section Title"}</h2>
  
          <p className='text-lg text-gray-800 text-center mb-8'>{doc.data.reasons_section_description ? doc.data.reasons_section_description : "Reason section description here..."}</p>
        </div>
  
        <StyledDiv className="w-11/12 max-w-screen-xl mx-auto">
          <Accordion preExpanded={[0]}>
            {doc.data.reasons.map((item, index) => {
              return (
                <AccordionItem uuid={index} key={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      {item.reason}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      {item.answer}
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        </StyledDiv>
      </div>

      <BenefitsSection />
      <QuickQuoteSection />
      <CapabilitiesSection />
    </Layout>
  )
}

export default withPrismicPreview(WhyNowPageTemplate)

export const WhyNowPageTemplateQuery = graphql`
  query($id: String!) {
    prismicWhyNowPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        page_short_description
        page_no_index
        hero_section_main_title
        hero_section_background_image {
          gatsbyImageData(
            width: 1920
          )
          alt
        }
        intro_section_title
        intro_section_content {
          richText
        }
        fact_section_title
        fact_section_description
        fact_section_source
        process_section_title
        first_process_title
        first_process_length
        first_process_description
        first_process_icon {
          gatsbyImageData(
            width: 800
          )
          alt
        }
        second_process_title
        second_process_length
        second_process_description
        second_process_icon {
          gatsbyImageData(
            width: 800
          )
          alt
        }
        third_process_title
        third_process_length
        third_process_description
        third_process_icon {
          gatsbyImageData(
            width: 800
          )
          alt
        }
        reasons_section_title
        reasons_section_description
        reasons {
          reason
          answer
        }
      }
    }
    imgDefault: file(relativePath: { eq: "header/header-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
        )
      }
    }
    imgDefaultFirstIcon: file(relativePath: { eq: "stabilize-icon.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 800
        )
      }
    }
    imgDefaultSecondIcon: file(relativePath: { eq: "standardize-icon-1.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 800
        )
      }
    }
    imgDefaultThirdIcon: file(relativePath: { eq: "strategize-icon.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          height: 800
        )
      }
    }
  }
`


