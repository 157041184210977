import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, StaticImage, getImage, getSrc } from "gatsby-plugin-image"
import styled from 'styled-components'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from "../components/layout/layout"
import SEOnew from "../components/seo-new"
import CapabilitiesSection from "../components/sections/capabilities-section"
import BenefitsSection from "../components/sections/benefits-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"

const StyledH2 = styled.h2`
  text-shadow: rgba(0, 0, 0, 0.6) 0px 3px 12px;
`

const TestimonialsPageTemplate = ({ data }) => {
  const doc = data.prismicTestimonialsPage
  const imgSrc = getSrc(doc.data.hero_section_background_image)
  const imgHero = getImage(doc.data.hero_section_background_image)
  const imgDefault = getImage(data.imgDefault)
  const testis = doc.data.testimonials  

  return (
    <Layout>
      <SEOnew 
        title={doc.data.page_title ? doc.data.page_title : null} 
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        image={imgSrc ? imgSrc : null}
        noIndex={doc.data.page_no_index ? true : false}
      />
      
      <div style={{ display: "grid" }}>
        <GatsbyImage
          style={{
            gridArea: "1/1",
            height: 450,
          }}
          width={1920}
          layout="constrained"
          alt={imgHero.alt ? imgHero.alt : "hero background image"}
          image={imgHero ? imgHero : imgDefault}
          formats={["auto", "webp", "avif"]}
        />
        <div
          style={{
            gridArea: "1/1",
            position: "relative",
            placeItems: "center",
            display: "grid",
          }}
        >
          <div className='w-full max-w-screen-lg flex flex-col xl:flex-row items-center mx-auto'>
            <div className='w-11/12 flex flex-col items-center mx-auto'>
              <h1 className="text-xl text-white font-medium text-center uppercase mb-4">{doc.data.hero_section_top_title ? doc.data.hero_section_top_title : "Hero Top Title"}</h1>
              <StyledH2 className='text-3xl md:text-5xl font-extrabold text-white uppercase text-center'>
                <span className='leading-tight'>{doc.data.hero_section_main_title ? doc.data.hero_section_main_title : "Hero Main Title"}</span>
              </StyledH2>
              <p className="text-xl text-white font-medium text-center mt-4">{doc.data.hero_section_description ? doc.data.hero_section_description : "Hero Section Description"}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white w-full pt-16 pb-24 border-t border-b">
        <div className="w-11/12 max-w-screen-lg mx-auto">
          <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>Why IT Proactive?</h2>

          {testis.map( testi => {
            return (
              <div className='p-6 mb-12 border-b border-site-lightblue'>
                <div className='flex items-center justify-center mb-4'>
                  <div>
                    <StaticImage
                      src='../../images/quote-icon-blue.png'
                      width={50}
                      layout="constrained"
                      alt="quote icon"
                      placeholder='tracedSVG'
                      formats={["auto", "webp", "avif"]}
                    />
                  </div>
                </div>

                <div className='mb-6'>
                  <p className='text-lg md:text-xl text-gray-800 mb-6 text-center'>{testi.client_testimonial ? testi.client_testimonial : 'More amazing testimonials from very satisfied clients here. More, more, more!'}</p>
                </div>       

                <div className='flex items-center justify-center space-x-4'>
                  <div>
                    <p className='text-gray-800 text-lg font-semibold'>{testi.client_name ? testi.client_name : 'Client Name'}</p>
                    <p className='text-gray-800 text-md italic font-medium'>{testi.client_company ? testi.client_company : 'Company Name'}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <BenefitsSection />
      <QuickQuoteSection />
      <CapabilitiesSection />
    </Layout>
  )
}

export default withPrismicPreview(TestimonialsPageTemplate)

export const TestimonialsPageTemplateQuery = graphql`
  query($id: String!) {
    prismicTestimonialsPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        page_title
        page_short_description
        page_no_index
        hero_section_top_title
        hero_section_main_title
        hero_section_description
        hero_section_background_image {
          gatsbyImageData(
            width: 1920
          )
          alt
        }
        testimonials {
          client_testimonial
          client_name
          client_company
        }
      }
    }
    imgDefault: file(relativePath: { eq: "header/header-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
        )
      }
    }
    imgDefaultClient: file(relativePath: { eq: "clients/client-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 80
          height: 80
        )
      }
    }
  }
`


