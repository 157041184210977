import React from "react";
import { useStaticQuery, graphql } from 'gatsby'

import StatusCounter from "../common/StatusCounter";

const MultipleStatusCountersSectionNew = () => {
  const data = useStaticQuery(graphql`
    query MultipleStatusCountersSectionNewQuery {
      prismicServiceLevelsSection {
        data {
          section_title
          section_description
          statuses {
            status_value
            status_suffix
            status_name
          }
        }
      }
    }
  `)

  const doc = data.prismicServiceLevelsSection

  return (
    <div className="bg-white w-full border-t border-gray-100 py-16">
      <div className="w-11/12 max-w-screen-lg mx-auto">
        <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-8'>{doc.data.section_title ? doc.data.section_title : "Section Title"}</h2>

        <p className='text-lg text-gray-800 text-center mb-2'>{doc.data.section_description ? doc.data.section_description : "Section Description"}</p>
      </div>

      <div
        className="w-11/12 max-w-screen-xl flex flex-row flex-wrap items-end space-x-6 xl:space-x-16 space-y-6 justify-center md:mx-auto"
      >
        {doc.data.statuses.map((stat, idx) => (
          <StatusCounter
            key={idx}
            endValue={stat.status_value}
            suffix={stat.status_suffix}
            statusName={stat.status_name}
            duration={4}
          />
        ))}
      </div>
    </div>
  );
};

export default MultipleStatusCountersSectionNew;