import React from "react"
import { graphql } from "gatsby"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import Layout from "../components/layout/layout"
import SEOnew from "../components/seo-new"
import HomeHeroSectionNew from "../components/sections/home-hero-section-new"
import HomeIntroSectionUpdate from "../components/sections/home-intro-section-update"
import HomeChecklistSectionNew from "../components/sections/home-checklist-section-new"
import HomeCtaSectionNew from "../components/sections/home-cta-section-new"
import HomeDavidSection from "../components/sections/home-david-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import MultipleStatusCountersSectionNew from "../components/sections/MultipleStatusCountersSectionNew"

const IndexPageTemplate = ({ data }) => {
  const doc = data.prismicHomePage

  return (
    <Layout>
      <SEOnew 
        title={doc.data.page_title ? doc.data.page_title : null} 
        description={doc.data.page_short_description ? doc.data.page_short_description : null}
        noIndex={doc.data.page_no_index ? true : false}
      />
      
      <HomeHeroSectionNew 
        title={doc.data.hero_section_title}
      />

      <HomeIntroSectionUpdate 
        title={doc.data.intro_section_title}
        description={doc.data.intro_section_description}
      />

      <MultipleStatusCountersSectionNew />

      <HomeChecklistSectionNew 
        title={doc.data.checklist_section_title}
        subTitle={doc.data.checklist_section_subtitle}
        description={doc.data.checklist_section_description}
      />

      <HomeDavidSection />

      <HomeCtaSectionNew 
        title={doc.data.cta_section_title}
        description={doc.data.cta_section_description}
      />

      <CapabilitiesSection />
    </Layout>
  )
}

export default withPrismicPreview(IndexPageTemplate)

export const IndexPageTemplateQuery = graphql`
  query {
    prismicHomePage {
      _previewable
      data {
        page_title
        page_short_description
        hero_section_title
        intro_section_title
        intro_section_description
        checklist_section_title
        checklist_section_subtitle
        checklist_section_description
        cta_section_title
        cta_section_description
      }
    }
    imgDefault: file(relativePath: { eq: "header/header-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
        )
      }
    }
  }
`